import React from "react";
import { Box, Typography, IconButton, Grid } from "@mui/material";
import theme from "../../moodyTheme";
import discordIcon from "../../assets/discord.png";
import tiktokIcon from "../../assets/tiktok.png";
import xIcon from "../../assets/x.png";
import instagramIcon from "../../assets/instagram.png";
import linkedinIcon from "../../assets/linkedin.png";

const FollowUs: React.FC = () => {
  const iconSize = 70;

  const isMobile = () => {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  };

  const getLink = (webUrl: string, appUrl: string) => {
    return isMobile() ? appUrl : webUrl;
  };

  return (
    <Box
      id="follow-us"
      sx={{
        ...theme.followUsBoxTheme,
        paddingTop: "8rem", // Added padding to the top to ensure the title is visible when scrolled to
      }}
    >
      <Typography sx={theme.darkHeaderStyles}>Follow Along With Us</Typography>
      <Grid container spacing={2} justifyContent="center">
        <Grid item>
          <IconButton
            href="https://discord.gg/EQE6R53F"
            target="_blank"
            sx={{ color: "#fff" }}
          >
            <img
              src={discordIcon}
              alt="Discord"
              width={iconSize}
              height={iconSize}
            />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton
            href={getLink(
              "https://www.tiktok.com/@theneptuneapp",
              "https://www.tiktok.com/@theneptuneapp"
            )}
            target="_blank"
            sx={{ color: "#fff" }}
          >
            <img
              src={tiktokIcon}
              alt="TikTok"
              width={iconSize}
              height={iconSize}
            />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton
            href={getLink(
              "https://x.com/theneptuneapp",
              "twitter://user?screen_name=theneptuneapp"
            )}
            target="_blank"
            sx={{ color: "#fff" }}
          >
            <img src={xIcon} alt="X" width={iconSize} height={iconSize} />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton
            href={getLink(
              "https://www.instagram.com/theneptuneapp/",
              "instagram://user?username=theneptuneapp"
            )}
            target="_blank"
            sx={{ color: "#fff" }}
          >
            <img
              src={instagramIcon}
              alt="Instagram"
              width={iconSize}
              height={iconSize}
            />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton
            href={getLink(
              "https://www.linkedin.com/company/theneptuneapp/",
              "linkedin://company/theneptuneapp"
            )}
            target="_blank"
            sx={{ color: "#fff" }}
          >
            <img
              src={linkedinIcon}
              alt="LinkedIn"
              width={iconSize}
              height={iconSize}
            />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FollowUs;
